import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, type, image, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            twitterUsername
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          property: `og:image`,
          content:
            image || `${site.siteMetadata.siteUrl}/graphics/logo-unfurl.png`,
        },
        {
          name: `twitter:card`,
          /* TODO support summary_with_image again, see https://github.com/rlcfchurch/website/issues/8 */
          /* content: cardType, */
          content: 'summary',
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.twitterUsername,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.twitterUsername,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content:
            /* TODO support unfurl image again, see https://github.com/rlcfchurch/website/issues/8 */
            // image || `${site.siteMetadata.siteUrl}/graphics/logo-sharing.png`,
            `${site.siteMetadata.siteUrl}/graphics/logo-sharing.png`,
        },
        ...meta,
      ]}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  type: `website`,
  cardType: `summary`,
  image: null,
};

SEO.propTypes = {
  description: PropTypes.string,
  type: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  cardType: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
