import React, { useState } from 'react';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';

import NavItem from './NavItem';

const useStyles = makeStyles((theme) => ({
  logo: {
    padding: theme.spacing(2),
    '& img': {
      display: 'block',
      maxHeight: '48px',
      marginBottom: 0,
      [theme.breakpoints.up('md')]: {
        maxHeight: '60px',
      },
    },
  },
  menuButton: {
    color: theme.palette.text.secondary,
  },
}));

const Nav = () => {
  const [showMenu, setShowMenu] = useState(false);
  const classes = useStyles();

  return (
    <>
      <Box component="nav" display="flex">
        <div className={classes.logo}>
          <Link to="/">
            <img
              src="/graphics/logo.png"
              alt="River of Life Christian Fellowship"
            />
          </Link>
        </div>

        <Hidden mdDown>
          <Box display="flex" justifyContent="flex-end" flex="1" pr={4}>
            <NavItem to="/">Home</NavItem>
            <NavItem to="/media">Media</NavItem>
            <NavItem to="/events">Events</NavItem>
            <NavItem to="/books">Books</NavItem>
            <NavItem to="/memory-verses/plan/2024">Verses</NavItem>
            <NavItem to="/about">About</NavItem>
            <NavItem to="/contact">Contact</NavItem>
            <NavItem to="/live">Live</NavItem>
            <NavItem to="/search">
              <Box mx={-4}>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </Box>
            </NavItem>
          </Box>
        </Hidden>

        <Hidden lgUp>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            flex="1"
            pr={1}
          >
            <IconButton
              component={Link}
              to="/search"
              size="medium"
              className={classes.menuButton}
            >
              <SearchIcon />
            </IconButton>

            <IconButton
              size="medium"
              onClick={() => setShowMenu(!showMenu)}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Hidden>
      </Box>

      <Hidden lgUp>
        <Collapse in={showMenu}>
          <Box
            component="nav"
            display="flex"
            flexDirection="column"
            px={4}
            py={2}
            mb={2}
          >
            <NavItem to="/" vertical>
              Home
            </NavItem>
            <NavItem to="/media" vertical>
              Media
            </NavItem>
            <NavItem to="/events" vertical>
              Events
            </NavItem>
            <NavItem to="/books" vertical>
              Books
            </NavItem>
            <NavItem to="/memory-verses/plan/2024" vertical>
              Verses
            </NavItem>
            <NavItem to="/about" vertical>
              About
            </NavItem>
            <NavItem to="/contact" vertical>
              Contact
            </NavItem>
            <NavItem to="/live" vertical>
              Live
            </NavItem>
          </Box>
        </Collapse>
      </Hidden>
    </>
  );
};

export default Nav;
