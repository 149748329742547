import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ src, alt }) => (
  <img
    src={src}
    alt={alt}
    style={{ display: 'block', margin: 0, maxWidth: '100%' }}
  />
);

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Image;
