import React from 'react';
import ReactMoment from 'react-moment';
import { Link } from 'gatsby';
import { useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

import Image from './Image';

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      py={12}
      style={{
        color: theme.palette.white.main,
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Container maxWidth="lg">
        <Box textAlign="center" mb={4}>
          <Typography variant="h3">Find Us Online</Typography>
        </Box>

        <Box display="flex" justifyContent="center" mb={4}>
          <Box mr={2}>
            <IconButton
              href="https://www.youtube.com/user/rlcfchurch"
              color="inherit"
            >
              <YouTubeIcon />
            </IconButton>
          </Box>

          <Box mr={2}>
            <IconButton
              href="https://www.facebook.com/rlcfchurch"
              color="inherit"
            >
              <FacebookIcon />
            </IconButton>
          </Box>

          <Box mr={2}>
            <IconButton href="https://twitter.com/rlcf_church" color="inherit">
              <TwitterIcon />
            </IconButton>
          </Box>

          <Box>
            <IconButton
              href="https://www.instagram.com/rlcfchurch"
              color="inherit"
            >
              <InstagramIcon />
            </IconButton>
          </Box>
        </Box>

        <Box display="flex" justifyContent="center" mb={2}>
          <Box maxWidth={240}>
            <Image
              src="/graphics/logo-footer.png"
              alt="River of Life Christian Fellowship"
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="center">
          <Typography variant="body2">
            &copy;
            <ReactMoment format="YYYY" />
            &nbsp; All rights reserved. Additional copyright information{' '}
            <MuiLink component={Link} to="/copyrights">
              here
            </MuiLink>
            .
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
