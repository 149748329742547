import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  link: ({ active, vertical }) => ({
    display: vertical ? 'block' : 'inline',
    width: vertical ? '100%' : 'auto',
    color: active ? theme.palette.text.secondary : theme.palette.text.primary,
    textDecoration: 'none',
  }),
}));

const NavItem = ({ to, href, vertical, children }) => {
  const { pathname } = useLocation();

  const active = to === pathname;
  const classes = useStyles({ active, vertical });

  return (
    <Box
      display="flex"
      alignItems="center"
      px={vertical ? 0 : 6}
      py={vertical ? 1 : 0}
    >
      <Typography
        component={href ? 'a' : Link}
        to={to}
        href={href}
        variant="subtitle1"
        className={classes.link}
      >
        {children}
      </Typography>
    </Box>
  );
};

NavItem.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  vertical: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

NavItem.defaultProps = {
  to: undefined,
  href: undefined,
  vertical: false,
};

export default NavItem;
